import React from "react";

function SvgCommunicatorApp(props) {
  return (
    <svg viewBox="0 0 60 60" width="1em" height="1em" {...props}>
      <g data-name="DESIGNED BY FREEPIK">
        <path fill="#fff" d="M0 0h60v60H0z" />
        <path
          data-name="Path 88"
          d="M42.78 12.5H17.23a7.11 7.11 0 00-6.93 7.27v16.81a4.35 4.35 0 004 4.63h2.86a.81.81 0 01.55.25.83.83 0 01.21.59l-.25 5.29a.26.26 0 00.24.29.26.26 0 00.21-.07l4.76-4.93.77-.8.38-.4a.79.79 0 01.38-.21h18.37a7.1 7.1 0 006.93-7.27V19.77a7.11 7.11 0 00-6.93-7.27zm-10 19H19.24a1.47 1.47 0 110-2.93h13.5a1.47 1.47 0 110 2.93zm7.14-7.24H19.24a1.47 1.47 0 110-2.93h20.64a1.47 1.47 0 010 2.93z"
          fill="#2091c8"
        />
      </g>
    </svg>
  );
}

export default SvgCommunicatorApp;

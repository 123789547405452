import React, { useEffect, useState } from 'react';
import './App.css';
import AuthService from './azureAuth';
import MsButton from './assets/MsSymbollockupSigninLight';
import MCASTLogo from './assets/MCAST_logo.png';
import Communicator from './assets/Communicator';
import CommunicatorApp from './assets/CommunicatorApp';
import Learing from './assets/Learning';
import LearingApp from './assets/LearningApp';
import VrApp from './assets/VrApp';
import ArApp from './assets/ArApp';

const App: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const isNoDisplay = query.get('no_display');
  const redirect = query.get('redirect');
  const type = query.get('type');
  const Auth = new AuthService(redirect || '');
  const [showApps, setShowApps] = useState(false);

  window.addEventListener('message', async ({ origin, data }) => {
    if (
      (origin.endsWith(process.env.REACT_APP_CLIENT_APP!) || origin.startsWith('http://localhost')) &&
      typeof data === 'object' &&
      data.type === 'getToken'
    ) {
      const { type, payload } = await Auth.getToken(data.scope);
      sendMessageToParent(type, payload, data.id);
    }
  });

  window.addEventListener('storage', async () => {
    const { type } = await Auth.getToken();
    if (type === 'token' && redirect) {
      window.location.assign(redirect);
    } else if (['login_required', 'user_login_error', 'null_or_empty_id_token'].includes(type)) {
      sendMessageToParent('loggedOut');
    }
  });

  const sendMessageToParent = (type: string, payload?: string, id?: string) => {
    if (isNoDisplay && window.parent) {
      window.parent.postMessage({ type, payload, id }, '*');
    }
  };

  const loginHandler = async () => {
    if (window.innerWidth < 1024) {
      Auth.login(true);
    } else {
      (Auth.login() as Promise<{ type: string; payload: string }>)
        .then(payload => {
          if (payload) {
            if (redirect) {
              window.location.assign(redirect);
            } else {
              setShowApps(true);
            }
          }
        })
        .catch(() => null);
    }
  };

  useEffect(() => {
    (async () => {
      const { type: tokenType } = await Auth.getToken();
      if (tokenType === 'token') {
        if (type === 'logout') {
          Auth.logout();
        } else if (type === 'login' && redirect) {
          window.location.assign(redirect);
        } else if (!redirect) {
          setShowApps(true);
        }
      } else {
        var res = document.cookie;
        var multiple = res.split(';');
        for (var i = 0; i < multiple.length; i++) {
          var key = multiple[i].split('=');
          document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        }
      }
    })();
  }, [type, Auth, redirect]);

  return (
    <div className="App">
      {!isNoDisplay && (
        <div className="holder">
          <img className="McastLogo" src={MCASTLogo} alt="mcast logo" />
          {!showApps ? (
            <>
              <h2>
                Welcome to the <strong>OTIS</strong> platform
              </h2>
              <div className="appIcons">
                <Communicator />
                <Learing />
                <VrApp />
                <ArApp />
              </div>
              <h3>Sign in with your MCAST credentials and gain access to all the apps</h3>
              <button onClick={loginHandler} data-testid="login_button">
                <MsButton />
              </button>
            </>
          ) : (
            <>
              <h2>
                You are signed in the <strong>OTIS</strong> platform
              </h2>
              <h3>Choose the app you want to use</h3>
              <div className="appIcons appLinks" data-testid="application_links">
                <a href={`${process.env.REACT_APP_LEARNING_APP}?login=true`}>
                  <LearingApp />
                </a>
                <a href={`${process.env.REACT_APP_COMMUNICATOR_APP}?login=true`}>
                  <CommunicatorApp />
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default App;

import { UserAgentApplication } from 'msal';

export default class AuthService {
  public static instance: AuthService;
  private applicationConfig!: {
    clientID: string;
    scopes: string[];
    authority: string;
    forceRefresh: boolean;
  };
  public app!: UserAgentApplication;

  constructor(redirectUrl: string) {
    if (AuthService.instance) {
      return AuthService.instance;
    } else {
      const isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
      const isSafari =
        /constructor/i.test(window.HTMLElement as any) ||
        (function(p) {
          return p.toString() === '[object SafariRemoteNotification]';
        })(
          !(window as any)['safari'] ||
            (typeof (window as any).safari !== 'undefined' && (window as any).safari.pushNotification)
        );
      const useIframe = !isIOS && !isSafari;
      AuthService.instance = this;
      this.applicationConfig = {
        clientID: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID!,
        scopes: [process.env.REACT_APP_AZURE_AUTH_SCOPES!],
        authority: process.env.REACT_APP_AZURE_AUTH_AUTHORITY!,
        forceRefresh: !useIframe,
      };
      this.app = new UserAgentApplication({
        auth: {
          clientId: this.applicationConfig.clientID,
          postLogoutRedirectUri: `${window.location.origin}?type=login&redirect=${redirectUrl}`,
        },
        cache: {
          storeAuthStateInCookie: true,
          cacheLocation: 'localStorage',
        },
        system: {
          tokenRenewalOffsetSeconds: !useIframe ? 3300 : 300,
        },
      });
      this.app.handleRedirectCallback(
        () => {},
        () => {}
      );
    }
  }

  public login = (redirect = false) => {
    if (!redirect) {
      return this.app.loginPopup(this.applicationConfig).then(
        e => this.getToken(),
        e => null
      );
    } else {
      return this.app.loginRedirect(this.applicationConfig);
    }
  };

  public logout = () => {
    this.app.logout();
  };

  public getToken = async (scope?: string) => {
    try {
      const { accessToken } = await this.app.acquireTokenSilent({
        scopes: scope ? [scope] : this.applicationConfig.scopes,
        authority: this.applicationConfig.authority,
      });
      return { type: 'token', payload: accessToken };
    } catch (error) {
      return { type: error.errorCode as string, payload: '' };
    }
  };
}

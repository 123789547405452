import React from "react";

function SvgArApp(props) {
  return (
    <svg viewBox="0 0 60 60" width="1em" height="1em" {...props}>
      <defs>
        <style>{".ar_app_svg__cls-2{fill:#c73b4e}"}</style>
      </defs>
      <g id="ar_app_svg__DESIGNED_BY_FREEPIK" data-name="DESIGNED BY FREEPIK">
        <path fill="#fff" d="M0 0h60v60H0z" />
        <g id="ar_app_svg__Group_85" data-name="Group 85">
          <path
            id="ar_app_svg__Path_60"
            data-name="Path 60"
            className="ar_app_svg__cls-2"
            d="M11.09 17.68a.85.85 0 00.85-.85V12.7a1 1 0 011-1h3.91a.85.85 0 100-1.7h-3.91a2.71 2.71 0 00-2.7 2.7v4.13a.85.85 0 00.85.85z"
          />
          <path
            id="ar_app_svg__Path_61"
            data-name="Path 61"
            className="ar_app_svg__cls-2"
            d="M17 48.3h-4.17a1 1 0 01-1-1v-3.92a.85.85 0 10-1.7 0v3.92a2.7 2.7 0 002.7 2.7H17a.85.85 0 000-1.7z"
          />
          <path
            id="ar_app_svg__Path_62"
            data-name="Path 62"
            className="ar_app_svg__cls-2"
            d="M48.92 42.32a.85.85 0 00-.85.85v4.13a1 1 0 01-1 1h-3.92a.85.85 0 000 1.7h3.91a2.7 2.7 0 002.7-2.7v-4.12a.85.85 0 00-.84-.86z"
          />
          <path
            id="ar_app_svg__Path_63"
            data-name="Path 63"
            className="ar_app_svg__cls-2"
            d="M47.17 10H43a.85.85 0 000 1.7h4.13a1 1 0 011 1v3.91a.85.85 0 101.7 0v-3.9A2.7 2.7 0 0047.17 10z"
          />
          <path
            id="ar_app_svg__Path_64"
            data-name="Path 64"
            className="ar_app_svg__cls-2"
            d="M43.87 37V24a.2.2 0 00-.31-.17l-12.38 7.4a.66.66 0 00-.33.58v14.3a.1.1 0 00.1.1H31l11.94-7.51a2 2 0 00.93-1.7z"
          />
          <path
            id="ar_app_svg__Path_65"
            data-name="Path 65"
            className="ar_app_svg__cls-2"
            d="M16.06 23.77a.22.22 0 00-.28.07.14.14 0 000 .11V37a2 2 0 00.92 1.67L29 46.14a.1.1 0 00.14 0V31.76a.7.7 0 00-.34-.59z"
          />
          <path
            id="ar_app_svg__Path_66"
            data-name="Path 66"
            className="ar_app_svg__cls-2"
            d="M42.8 21.92l-12.38-8.15a1.14 1.14 0 00-1.25 0l-12.35 8.15a.19.19 0 00-.06.27.16.16 0 00.07.07L29.65 30a.66.66 0 00.7 0l12.44-7.71a.21.21 0 00.07-.28.17.17 0 00-.06-.09z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgArApp;
